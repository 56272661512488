// PrimeNG
.p-dropdown-panel .p-dropdown-items,
.p-column-filter-row-items {
  margin-bottom: 0px;
  padding: 0px;
}

.p-inputtext {
  font-size: 1em;
}

.p-dropdown span[role="combobox"] .ng-star-inserted {
  display: flex;
  height: 100%;
  font-size: 1em;
  flex-direction: column;
  justify-content: center;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: var(--bs-primary) !important;
}

.p-overlay {
  left: -1px !important;

  ul[role="listbox"] {
    margin-bottom: 0px !important;
    padding: 0px !important;
  }
}

// Bootstrap
.btn-outline-primary {
  --bs-btn-hover-color: #fff;
}

// Theme
.h-50px {
  height: 50px !important;
}

body {
  width: 100vw;
  height: 100vh;
}
